import React from 'react'
import { Header as HeaderComponent } from '@damen/ui'

import Link from '@/components/Link'
import { Color } from '@/components/Link/types'
import { HeaderVideo } from '@/queries/schema.generated'

interface Props {
  content: HeaderVideo
}

const VideoHeader: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  return (
    <HeaderComponent.Video
      title={content.title}
      label={content.label}
      text={content.text}
      buttonComponent={
        content.link &&
        (() => (
          <Link.VNext
            type="button"
            color={content.buttonType as Color}
            link={content.link}
            text={content.linkText}
            testId="header.button"
          />
        ))
      }
      video={content.video.filename}
    />
  )
}

export default VideoHeader
