import React from 'react'

import { useQuery } from '@apollo/client'
import Layout from '@/components/layout'
import Presentational from '@/screens/ContentPage/Presentational'

import { Screen } from '@/types'
import { GetContentPageDocument, GetContentPageQuery } from '@/queries/queries/getContentPage.generated'

const ContentPage: React.FC<React.PropsWithChildren<Screen>> = ({ slug }) => {
  const { loading, data } = useQuery<GetContentPageQuery>(GetContentPageDocument, {
    variables: {
      id: slug,
    },
  })

  const contentPageHeader: any = data?.contentPage?.content?.header

  const headerVariant =
    !contentPageHeader ||
    contentPageHeader.__typename === 'HeaderSecondaryNoImage' ||
    contentPageHeader.__typename === 'HeaderArticle' ||
    contentPageHeader.__typename === 'HeaderError'
      ? 'dark'
      : 'light'
  return (
    <Layout.Default
      contactData={data?.modalContact?.content}
      departmentsContactData={data?.modalDepartmentsContact}
      departmentsNewsletterData={data?.modalDepartmentsNewsletter}
      donationData={data?.modalDonation}
      feedbackData={data?.modalFeedback?.content}
      footerData={data?.footer}
      loading={loading}
      newsletterData={data?.modalNewsletter?.content}
      seoData={data?.contentPage?.content?.seo}
      headerVariant={headerVariant}
      siteData={data?.site}
    >
      <Presentational content={data?.contentPage?.content as any} quoteData={data?.modalQuote} />
    </Layout.Default>
  )
}

export default ContentPage
