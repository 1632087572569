import React from 'react'
import { theme } from '@damen/ui'

import dynamic from 'next/dynamic'
import ModalQuoteContextProvider from '@/contexts/modalQuoteContext'
import ModalDownloadContextProvider from '@/contexts/modalDownloadContext'

import { PrimaryHeader, SecondaryHeader, HeaderError } from '@/components/UI'
import { useBreadcrumb } from '@/hooks'
import { ModalDownloadFragment } from '@/queries/fragments/Modal/Download.generated'
import { ModalQuoteFragment } from '@/queries/fragments/Modal/Quote.generated'

import { ModalProps, QuoteModalProps, UsedOrCharterProductModalProps } from '@/components/UI/Modal/types'
import VideoHeader from '@/components/UI/VideoHeader'
import { BlokZone } from '@/components/UI/BlokZone'
import Components from '@/components/Components'
import { Content, HeaderVideo } from '@/queries/schema.generated'

const DownloadModal = dynamic(() => import('@/components/Modal/Download'), { ssr: false })
const FamilyQuoteModal = dynamic(() => import('@/components/Family/Modal/Quote'), { ssr: false })

interface Props {
  content?: Content
  downloadData?: ModalDownloadFragment
  quoteData?: ModalQuoteFragment
  selectedModal?: ModalProps
  handleCloseModal?: () => void
  setOpenModal?: (props: ModalProps) => void
}

const Presentational: React.FC<React.PropsWithChildren<Props>> = ({
  content,
  downloadData,
  quoteData,
  setOpenModal,
  selectedModal,
  handleCloseModal,
}) => {
  const body = content?.body
  const header = content?.header

  const headerTypeNotUnknown = header?.__typename !== 'Unknown' ? header : null

  const breadcrumb = useBreadcrumb(
    headerTypeNotUnknown?.__typename !== 'HeaderVideo' ? headerTypeNotUnknown?.breadcrumb?.title : '',
    headerTypeNotUnknown?.__typename !== 'HeaderVideo' ? headerTypeNotUnknown?.breadcrumb?.link : null,
    headerTypeNotUnknown?.__typename === 'HeaderSecondaryNoImage' ? 'marineBlack' : 'white',
    headerTypeNotUnknown?.__typename !== 'HeaderPrimary' && headerTypeNotUnknown?.__typename !== 'HeaderSplit'
      ? theme.colors.marineBlack
      : theme.colors.white,
  )

  const selectModalQuoteProps = ['quote', 'usedProductQuote', 'charterProductQuote'].includes(selectedModal?.type)
    ? (selectedModal as QuoteModalProps | UsedOrCharterProductModalProps)
    : undefined

  // TODO: Check types
  return (
    <>
      <ModalQuoteContextProvider modalQuoteData={quoteData ?? undefined}>
        <ModalDownloadContextProvider modalDownloadData={downloadData}>
          <section>
            {headerTypeNotUnknown && headerTypeNotUnknown.__typename === 'HeaderError' && (
              <HeaderError
                content={{
                  id: headerTypeNotUnknown.id,
                  // @ts-expect-error TODO Type will need to be checked
                  header: { ...(headerTypeNotUnknown) },
                }}
              />
            )}

            {headerTypeNotUnknown && headerTypeNotUnknown.__typename === 'HeaderVideo' && (
              <VideoHeader content={header as HeaderVideo} />
            )}

            {headerTypeNotUnknown &&
              headerTypeNotUnknown.__typename !== 'HeaderPrimary' &&
              headerTypeNotUnknown.__typename !== 'HeaderError' &&
              headerTypeNotUnknown.__typename !== 'HeaderVideo' && (
                <SecondaryHeader
                  content={{
                    id: headerTypeNotUnknown.id,
                    // @ts-expect-error TODO Type will need to be checked
                    header: { ...(headerTypeNotUnknown), breadcrumb },
                  }}
                />
              )}

            {headerTypeNotUnknown && headerTypeNotUnknown.__typename === 'HeaderPrimary' && (
              <PrimaryHeader
                content={{
                  id: 'header',
                  header: { ...(header as any), breadcrumb },
                }}
              />
            )}
          </section>
          <BlokZone bloks={body} componentLookup={Components} additionalProps={{ setOpenModal }} />
        </ModalDownloadContextProvider>
      </ModalQuoteContextProvider>
      {downloadData && (
        <DownloadModal
          selectedModal={selectedModal?.type === 'download' ? selectedModal : null}
          handleCloseModal={handleCloseModal}
          modalDownload={downloadData.content}
        />
      )}
      {quoteData && (
        <FamilyQuoteModal
          selectedModal={selectModalQuoteProps}
          handleCloseModal={handleCloseModal}
          modalQuote={quoteData.content}
        />
      )}
    </>
  )
}

export default Presentational
